import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import LetterAnimation from '../LetterAnimation';
import { collection, getDocs } from '@firebase/firestore';
import { db } from '../../firebase';
import { Helmet } from 'react-helmet';
import './index.scss';

const Portfolio = () => {
	const [letterClass, setLetterClass] = useState('text-animate');
	const [projects, setProjects] = useState([]);
	
	useEffect(() => {
		setTimeout(() => {
			setLetterClass('text-animate-hover');
		}, 3000)
	}, [])
	
	useEffect(() => {
		const projectsCollection = collection(db, 'portfolio');
		
		const fetchProjectsData = async () => {
			const querySnapshot = await getDocs(projectsCollection);
			
			const data = querySnapshot.docs.map((doc) => ({ 
				id: doc.id,
				...doc.data()
			}));
			
			setProjects(data);
		};
		
		fetchProjectsData();
		
	}, []);

		
	return (
	<>	
		<Helmet>
			<title>Portfolio | Pretty Pandey</title>
			{/*  SEO */}
			<meta name='description' content='A collection containing some of my work.' />
			<link rel="canonical" href="https://www.prettypandey.tech/portfolio" />
			<meta property="og:type" content="article" />
			<meta property="og:title" content="Portfolio - Pretty Pandey | Full Stack Programmer" />
			<meta property="og:url" content="https://www.prettypandey.tech/portfolio" />
			<meta property="og:site_name" content="Pretty Pandey" />
			<meta property="og:image" content="" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content="Portfolio - Pretty Pandey | Full Stack Programmer" />
			<meta name="twitter:site" content="@prettyppandey" />
			<meta name="twitter:image" content="" />
			<meta name="twitter:creator" content="@prettyppandey" />
		</Helmet>
		<div className='container portfolio-page'>
			<h1 className='page-title'>
					<LetterAnimation
					letterClass={letterClass}
					strArray={'Portfolio'.split('')}
					idx={15}
					/>
				</h1>
				<div className='projects'>
					<ul className='images-container'>
					{projects.map((project) => (
							<li 
								className='image-box' key={project.id}>
								<img src={project.imageUrls[0]} className='portfolio-image' alt={`${project.title} - Project Cover`} />{/*cover image*/}
								<div className='content'>
									<span className='title'>{project.name}</span>{/*project name*/}
									<span className='description'>{project.type}</span>{/*project type*/}
									<span className='stack'>{project.stack}</span>{/*project stack*/}
									<Link to={`/portfolio/${project.id}`} className='btn'>View Project</Link>
								</div>
							</li>
							)
						)
						.sort((a,b) => a.timestamp > b.timestamp ? -1 : 1)
					}
					</ul>
			</div> 
		</div>
		<Loader type='ball-clip-rotate-multiple' />
	</>
	
	)
}

export default Portfolio;
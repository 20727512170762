import Loader from 'react-loaders';
import LetterAnimation from '../LetterAnimation';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import './index.scss';

const Skills = () => {
	const [letterClass, setLetterClass] = useState('text-animate');
	
	useEffect(() => {
		setTimeout(() => {
			setLetterClass('text-animate-hover');
		}, 3000)
	}, [])
	
	return (
		<>
			<Helmet>
				<title>My Skills | Pretty Pandey</title>
				{/*  SEO */}
				<meta name='description' content='A brief description of the skill set I possess.' />
				<link rel="canonical" href="https://www.prettypandey.tech/skills" />
				<meta property="og:type" content="article" />
				<meta property="og:title" content="Skills - Pretty Pandey | Full Stack Programmer" />
				<meta property="og:url" content="https://www.prettypandey.tech/skills" />
				<meta property="og:site_name" content="Pretty Pandey" />
				<meta property="og:image" content="%PUBLIC_URL%/seo/SkillsPage.png" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Skills - Pretty Pandey | Full Stack Programmer" />
				<meta name="twitter:site" content="@prettyppandey" />
				<meta name="twitter:image" content="%PUBLIC_URL%/seo/SkillsPage.png" />
				<meta name="twitter:creator" content="@prettyppandey" />
			</Helmet>
			<div className='container skills-page'>
				<div className='text'>
					<h1>
						<LetterAnimation
						letterClass={letterClass}
						strArray={'Skills'.split('')}
						idx={15}/>
					</h1>
					<p className='skills-text'>My expertise lies in a robust technology stack that powers my projects. I've highlighted key languages and technologies, along with links to repositories showcasing their application.</p>
					
					<p><strong>Technology Stack:</strong><br />
						Frontend: <small><span>HTML5, CSS3, JavaScript(ES6), React.js, SASS, Bootstrap, Tailwind</span></small><br />
						Backend: <small><span>C#, Python, Flask, Django, TypeScript, Nodejs, Express.js</span></small><br/>
						Database: <small><span>MongoDB, MySQL, NoSQL</span></small><br/>
						Version Control: <small><span>Git, GitHub</span></small>
					</p>
					<p><strong>Design Aesthetics:</strong><br/>
					While I may not be a professional designer, my work reflects a keen sense of aesthetics. I specialize in developing responsive, mobile-first web designs that prioritize a seamless user experience. Leveraging design tools such as Adobe and Figma, I ensure that each project not only functions flawlessly but also captivates visually.
					</p>
					<p><strong>User Experience Optimization:</strong><br/>
					Beyond just writing code, I am committed to optimizing it for an exceptional user experience. Whether it's streamlining navigation, enhancing performance, or refining interactions, I prioritize the end user in every line of code I write.
					</p>
					<p>Feel free to explore the repositories linked to witness firsthand how I merge technology and design to create compelling and user-centric solutions.</p>

					{/* <p><strong>Back-end Programming and Development</strong><br />
						<span>C#</span>, <span>Python</span>, <span>Flask</span>, <span>Django</span>, <span>TypeScript</span>, <span>Nodejs</span>, <span>JavaScript(ES6)</span>, <span>SQL</span>, <span>NoSQL</span>.</p>
					<p>
						<strong>Front-end Development</strong><br />
						<span>JavaScript(ES6)</span>, <span>React</span>, <span>Html5</span>, <span>CSS</span>, <span>SASS</span>, <span>Bootstrap</span>, <span>Tailwind</span>.
					</p> */}
				</div>
				
				<div className='skills-repos'>
				
					<div>
						<h3>C#</h3>
						<p><a href='https://github.com/pandeypearl/simple_shell' 
						target='_blank'
						rel='noreferrer'>
						<FontAwesomeIcon icon={faGithub} color='#f4d6cc' font-size='12px' />
						&nbsp;Simple Shell - UNIX command line interpreter</a></p>

					</div>
					
					<div>
						<h3>Python</h3>
						<p><a href='https://github.com/pandeypearl/AirBnB_clone' 
						target='_blank'
						rel='noreferrer'>
						<FontAwesomeIcon icon={faGithub} color='#f4d6cc' font-size='12px' />
						&nbsp;AirBnB_clone - Command interpreter to manage AirBnB objects.</a></p>
					</div>
					
					<div>
						<h3>Flask</h3>
						<p><a href='https://github.com/pandeypearl/AirBnB_clone_v2' 
						target='_blank'
						rel='noreferrer'>
						<FontAwesomeIcon icon={faGithub} color='#f4d6cc' font-size='12px' />
						&nbsp;AirBnB_clone_v2 - Building a web framework with flask.</a></p>
					</div>
					
					<div>
						<h3>Django</h3>
						<p><a href='https://github.com/pandeypearl/NatHair' 
						target='_blank'
						rel='noreferrer'>
						<FontAwesomeIcon icon={faGithub} color='#f4d6cc' font-size='12px' />
						&nbsp;NatHair - Web Application - ALX/Holberton School Full Stack Programming Course final project.</a></p>
					</div>
					
					<div>
						<h3>Nodejs</h3>
						<p><a href='https://github.com/pandeypearl/NatHairAPI' 
						target='_blank'
						rel='noreferrer'>
						<FontAwesomeIcon icon={faGithub} color='#f4d6cc' font-size='12px' />
						&nbsp;NatHairAPI - API for ALX/Holberton School Full Stack Programming Course final project.</a></p>
					</div>
					
					<div>
						<h3>TypeScript</h3>
						<p><a href='https://github.com/pandeypearl/alx-backend-javascript/tree/master/0x04-TypeScript' 
						target='_blank'
						rel='noreferrer'>
						<FontAwesomeIcon icon={faGithub} color='#f4d6cc' font-size='12px' />
						&nbsp;TypeScript - School Personel Administration.</a></p>
					</div>
					
					<div>
						<h3>MySQL</h3>
						<p><a href='https://github.com/pandeypearl/AirBnB_clone_v2'
						target='_blank'
						rel='noreferrer'>
						<FontAwesomeIcon icon={faGithub} color='#f4d6cc' font-size='12px' />
						&nbsp;AirBnB Clone MySQL - Handeling two different storage engines with same codebase.</a></p>
					</div>
				</div>
			</div>
			<Loader type='ball-clip-rotate-multiple' />
		</>
	)
}

export default Skills;

import { auth, storage, db } from '../../firebase';
import React, { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';
import './index.scss';


const Home = () => {
	const [coverImage, setCoverImage] = useState(null);
	const [name, setName] = useState('');
	const [type, setType] = useState('');
	const [stack, setStack] = useState('');
	const [url, setUrl] = useState('');
	const [repo, setRepo] = useState('');
	const [description, setDescription] = useState('');
	const [features, setFeatures] = useState('');
	const [introBgColor, setIntroBgColor] = useState('');
	const [introTextColor, setIntroTextColor] = useState('');
	const [infoBgColor, setInfoBgColor] = useState('');
	const [infoTextColor, setInfoTextColor] = useState('');
	const [projectImage1, setProjectImage1]= useState(null);
	const [projectImage2, setProjectImage2]= useState(null);
	const [projectImage3, setProjectImage3]= useState(null);
	const [projectImage4, setProjectImage4]= useState(null);
	const [error, setError] = useState('');
	
	const coverImageChange = (event) => {
		setCoverImage(event.target.files[0]);
	}
	
	const projectImage1Change = (event) => {
		setProjectImage1(event.target.files[0]);
	}
	
	const projectImage2Change = (event) => {
		setProjectImage2(event.target.files[0]);
	}
	
	const projectImage3Change = (event) => {
		setProjectImage3(event.target.files[0]);
	}
	
	const projectImage4Change = (event) => {
		setProjectImage4(event.target.files[0]);
	}
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		
		try {
			//Uploading images to firebase storage
			const coverImageRef = ref(storage, `portfolio/${coverImage.name}`);
			await uploadBytes(coverImageRef, coverImage);
			
			const projectImage1Ref = ref(storage, `portfolio/${projectImage1.name}`);
			await uploadBytes(projectImage1Ref, projectImage1);
			
			const projectImage2Ref = ref(storage, `portfolio/${projectImage2.name}`);
			await uploadBytes(projectImage2Ref, projectImage2);
			
			const projectImage3Ref = ref(storage, `portfolio/${projectImage3.name}`);
			await uploadBytes(projectImage3Ref, projectImage3);
			
			const projectImage4Ref = ref(storage, `portfolio/${projectImage4.name}`);
			await uploadBytes(projectImage4Ref, projectImage4);
			
			// Getting download URLS
			const coverImageUrl = await getDownloadURL(coverImageRef);
			const projectImage1Url = await getDownloadURL(projectImage1Ref);
			const projectImage2Url = await getDownloadURL(projectImage2Ref);
			const projectImage3Url = await getDownloadURL(projectImage3Ref);
			const projectImage4Url = await getDownloadURL(projectImage4Ref);
			
			//Saving portfolio properites to filestore
			const imagesCollection = collection(db, 'portfolio');
			await addDoc(imagesCollection, {
				name,
				type,
				stack,
				url,
				repo,
				description,
				features,
				introBgColor,
				introTextColor,
				infoBgColor,
				infoTextColor,
				imageUrls: [
					coverImageUrl,
					projectImage1Url,
					projectImage2Url,
					projectImage3Url,
					projectImage4Url
				],
			});
			
			//Clearing form fields
			setCoverImage(null);
			setName('');
			setType('');
			setStack('');
			setUrl('');
			setRepo('');
			setDescription('');
			setFeatures('');
			setIntroBgColor('');
			setIntroTextColor('');
			setInfoBgColor('');
			setInfoTextColor('');
			setProjectImage1(null);
			setProjectImage2(null);
			setProjectImage3(null);
			setProjectImage4(null);
			setError('');
		} catch (error) {
			setError('An error occured. Please try again later.');
			console.error('Error:', error);
		}
	}
	return (
		<div className='dashboard'>
			<form onSubmit={handleSubmit} className='project-form'>
				
				<p className='mid'><input 
					type='text' 
					value={name} 
					placeholder='Project Name'
					required
					onChange={(e) => setName(e.target.value)} />
				</p>
				<p className='mid'><input 
					type='text' 
					value={type}
					placeholder='Project Type' 
					required
					onChange={(e) => setType(e.target.value)} />
				</p>
				<p><textarea
					value={stack}
					placeholder='Project Stack'
					required
					onChange={(e) => setStack(e.target.value)}>
				</textarea></p>
				<p><input 
					type='text' 
					value={url}
					placeholder='Project Url'
					required
					onChange={(e) => setUrl(e.target.value)} />
				</p>
				<p><input 
					type='text'
					value={repo}
					placeholder='Project Repository' 
					required
					onChange={(e) => setRepo(e.target.value)} />
				</p>
				<p><textarea 
					value={description}
					placeholder='Project Description'
					required
					onChange={(e) => setDescription(e.target.value)} >
				</textarea></p>
				<p><textarea 
					value={features}
					placeholder='Project Features'
					required
					onChange={(e) => setFeatures(e.target.value)} >
				</textarea></p>
				<p><input 
					type='text' 
					value={introBgColor}
					placeholder='Intro Background Color' 
					required
					onChange={(e) => setIntroBgColor(e.target.value)} />
				</p>
				<p><input 
					type='text' 
					value={introTextColor}
					placeholder='Intro Text Color' 
					required
					onChange={(e) => setIntroTextColor(e.target.value)} />
				</p>
				<p><input 
					type='text' 
					value={infoBgColor}
					placeholder='Info Background Color' 
					required
					onChange={(e) => setInfoBgColor(e.target.value)}/>
				</p>
				<p><input 
					type='text' 
					value={infoTextColor}
					placeholder='Info Text Color' 
					required
					onChange={(e) => setInfoTextColor(e.target.value)}/>
				</p>
				<p><input 
					type='file' 
					placeholder='Cover Image' 
					className='cover-image' 
					required
					onChange={coverImageChange} />
				</p>
				<p><input 
					type='file' 
					placeholder='Image One' 
					className='image-one' 
					required
					onChange={projectImage1Change} />
				</p>
				<p><input 
					type='file' 
					placeholder='Image Two' 
					className='image-two' 
					required
					onChange={projectImage2Change} /></p>
				<p><input 
					type='file' 
					placeholder='Image Three' 
					className='image-three' 
					required
					onChange={projectImage3Change} />
				</p>
				<p><input 
					type='file' 
					placeholder='Image Four' 
					className='image-four' 
					required
					onChange={projectImage4Change}/>
				</p>
				<button type='submit' className='form-btn'>Submit</button>
				{error && <p style={{ color: 'red' }}>{error}</p>}
				<button className='form-btn' onClick={() => auth.signOut()}>Sign out</button>
			</form>
		</div>
	)
}

export default Home;
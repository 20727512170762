import Loader from 'react-loaders';
import LetterAnimation from '../LetterAnimation';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import './index.scss';


const Contact = () => {
	const [letterClass, setLetterClass] = useState('text-animate')
	const refForm = useRef()
	
	useEffect(() => {
		setTimeout(() => {
			setLetterClass('text-animate-hover');
		}, 3000)
		
	}, [])
	
	const sendEmail = (e) => {
		e.preventDefault()
		
		emailjs
			.sendForm('service_ca4na9o', 'template_q7c9opq', refForm.current, 'swhdvLZoWEKfHfMPf')
			.then(
				() => {
					alert('Message successfully sent!')
					window.location.reload(false)
				},
				() => {
					alert('failed to send the message, please try again.')
				}
			)
	}
	
	return (
	<>
		<Helmet>
			<title>Contact Me | Pretty Pandey</title>
			{/*  SEO */}
			<meta name='description' content='Get in touch with me.' />
			<link rel="canonical" href="https://www.prettypandey.tech/contact" />
			<meta property="og:type" content="article" />
			<meta property="og:title" content="Contact - Pretty Pandey | Full Stack Programmer" />
			<meta property="og:url" content="https://www.prettypandey.tech/contact" />
			<meta property="og:site_name" content="Pretty Pandey" />
			<meta property="og:image" content="%PUBLIC_URL%/seo/ContactPage.png" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content="Contact - Pretty Pandey | Full Stack Programmer" />
			<meta name="twitter:site" content="@prettyppandey" />
			<meta name="twitter:image" content="%PUBLIC_URL%/seo/ContactPage.png" />
			<meta name="twitter:creator" content="@prettyppandey" />
		</Helmet>
		<div className='container contact-page'>
			<div className='text'>
				<h1>
					<LetterAnimation
						letterClass={letterClass}
						strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e']}
						idx={15}/>
				</h1>
				<p>
				Ready to kickstart a project or discuss career opportunities? Reach out using the form below. Whether it's a freelance gig, contract role, or a permanent position, I'm eager to connect with you!
				</p>
				
				<div className='contact-form'>
					<form ref={refForm} onSubmit={sendEmail}>
						<ul>
							<li className='mid'>
								<input type='text' name='name' placeholder='Name' required />
							</li>
							<li className='mid'>
								<input type='email' name='email' placeholder='Email' required />
							</li>
							<li>
								<input placeholder="Subject" type='text' name='subject' required />
							</li>
							<li>
								<textarea placeholder='Message' name='message' required></textarea>
							</li>
							<li>
								<input type='submit' className='contact-btn' value='SEND' />
							</li>
						</ul>
					</form>
				</div>
			</div>
			
			<div className='contact-info'>
				<ul>
					<li>
						<FontAwesomeIcon className='contact-icon email-icon' icon={faEnvelope} color='#f4d6cc' />
						<span>prettypandeypearl@gmail.com</span>
					</li>
					<li>
						<FontAwesomeIcon className='contact-icon phone-icon' icon={faPhone} color='#f4d6cc' />
						<span>+27 833028665</span>
					</li>
				</ul>
			</div>
		</div>
		<Loader type='ball-clip-rotate-multiple' />
	</>
	)
}

export default Contact;

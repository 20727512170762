// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup  } from 'firebase/auth';
import { getStorage } from '@firebase/storage';
import { getFirestore } from '@firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDw2yq-hH-cr-n6mPKzVuSTvrLfldf11Yo",
  authDomain: "react-portfolio-dashboar-3ac3a.firebaseapp.com",
  projectId: "react-portfolio-dashboar-3ac3a",
  storageBucket: "react-portfolio-dashboar-3ac3a.appspot.com",
  messagingSenderId: "660978805805",
  appId: "1:660978805805:web:57676807677aa97f9d5ca4",
  measurementId: "G-R8J5KVQSS6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);

export const signInWithGoogle = () => signInWithPopup(auth, provider);
export { analytics };
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from '@firebase/firestore';
import Loader from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose, faRocket, } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import './index.scss';


const PortfolioDetail = () => {
	const { id } = useParams();
	const [project, setProject] = useState(null);
	
	useEffect(() => {
		const projectDocRef = doc(db, 'portfolio', id);
		
		const fetchProjectDetail = async () => {
			const docSnapshot = await getDoc(projectDocRef);
			if (docSnapshot.exists()) {
				setProject(docSnapshot.data());
			}
		};
		
		fetchProjectDetail();
	}, [id]);
	
	if (!project) {
		return <p>Loading Project...</p>
	}
	
	const introStyle = {
		background: project.introBgColor,
		color: project.introTextColor,
	}
	
	const introBtnStyle = {
		border: `1px solid ${project.introTextColor}`,
	}
	
	const introBtnHoverStyle = {
		backgroundColor: project.introTextColor,
		color: project.introBgColor,
	}
	
	const infoStyle = {
		backgroundColor: project.infoBgColor,
		color: project.infoTextColor,
	}
	
	return (
		<>
			<div className='container portfolio-detail-page'>
				<div className='close-links'>
					<Link className='close' to='/portfolio'>
						<FontAwesomeIcon icon={faArrowLeft} />
					</Link>
					<Link className='close' to='/portfolio'>
					<FontAwesomeIcon icon={faClose} />
					</Link>
				</div>
				{project && (
					<div className='project-content'>
						<div className='intro' style={introStyle}> {/*intro background color*/}
							<div className='intro-text'>	{/*intro text color*/}
								<h2>{project.name}</h2>	{/*project name*/}
								<h3>{project.type}</h3> {/*project type*/}
								<p>{project.stack}</p> {/*project stack*/}
							</div>

							<div className='intro-img'>
								<img src={project.imageUrls[0]} className='portfolio-image' alt={`${project.title} - Project Cover`} />{/*cover image*/}
							</div>
							
							<div className='intro-links'>
								{/* project url*/}
								<button 
									className='intro-link-btn'
									style={introBtnStyle}
									onMouseEnter={(e) => {
										e.currentTarget.style.backgroundColor = introBtnHoverStyle.backgroundColor;
										e.currentTarget.style.color = introBtnHoverStyle.color;
									}}
									onMouseLeave={(e) => {
										e.currentTarget.style.backgroundColor = project.introBgColor;
										e.currentTarget.style.color = 'inherit';
									}}
									onClick={() => window.open(project.url)}
								><FontAwesomeIcon icon={faRocket} />&nbsp; Launch Site
								</button>
								{/*project repo*/} 
								<button 
									className='intro-link-btn'
									style={introBtnStyle}
									onMouseEnter={(e) => {
										e.currentTarget.style.backgroundColor = introBtnHoverStyle.backgroundColor;
										e.currentTarget.style.color = introBtnHoverStyle.color;
									}}
									onMouseLeave={(e) => {
										e.currentTarget.style.backgroundColor = project.introBgColor;
										e.currentTarget.style.color = 'inherit';
									}}
									onClick={() => window.open(project.repo)}
								><FontAwesomeIcon icon={faGithub} />&nbsp; View Code
								</button>
							</div>
						</div>
						
						<div className='info' style={infoStyle}> {/*info background color*/} {/*info text color*/}
							<p>{project.description}</p> {/*project description*/}
							<h3>Features</h3>
							<p>{project.features}</p>{/*project-features*/}
						</div>
						
						<div className='image-gallery'>
							<img src={project.imageUrls[1]} alt={project.name} /> {/*image one*/}
							<img src={project.imageUrls[2]} alt={project.name} /> {/* image two*/}
							<img src={project.imageUrls[3]} alt={project.name} /> {/* image three*/}
							<img src={project.imageUrls[4]} alt={project.name} /> {/*image four*/}
						</div>
						
						<div className='outro'>
							<p>If you like this project and would like something similar built for you, please get in touch.</p>
							<Link to='/contact' className='outro-link'>contact me</Link>
						</div>
						
					</div>
				)}
			</div>
			<Loader type='ball-clip-rotate-multiple' />
		</>
	);
}

export default PortfolioDetail;
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LetterAnimation from '../LetterAnimation';
import Image from './Image';
import Loader from 'react-loaders';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faBehance } from '@fortawesome/free-brands-svg-icons';
import './index.scss';


const Home = () => {
	const [letterClass, setLetterClass] = useState('text-animate')
	const nameArray = [' ','P', 'r', 'e', 't', 't', 'y',' ', 'P', 'a', 'n', 'd', 'e', 'y', ',']
	const jobArray = ['F', 'u', 'l', 'l',' ', 'S', 't', 'a', 'c', 'k',' ', 'P', 'r', 'o', 'g', 'r', 'a', 'm', 'm', 'e', 'r', '.']
	
	useEffect(() => {
		setTimeout(() => {
			setLetterClass('text-animate-hover');
		}, 4000)
		
	}, [])
	
	return (
		<>
		<Helmet>
            <title>Pretty Pandey | Full Stack Programmer</title>
			{/*  SEO */}
			<meta name='description' content='Pretty Pandey - Full Stack Programmer' />
			<link rel="canonical" href="https://www.prettypandey.tech" />
			<meta property="og:type" content="article" />
			<meta property="og:title" content="Pretty Pandey | Full Stack Programmer" />
			<meta property="og:url" content="https://www.prettypandey.tech" />
			<meta property="og:site_name" content="Pretty Pandey" />
			<meta property="og:image" content="%PUBLIC_URL%/seo/LandingPage.png" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content="Pretty Pandey | Full Stack Programmer" />
			<meta name="twitter:site" content="@prettyppandey" />
			<meta name="twitter:image" content="%PUBLIC_URL%/seo/LandingPage.png" />
			<meta name="twitter:creator" content="@prettyppandey" />
		</Helmet>
		<div className='container home-page'>
			<div className='text'>
				<h1>
				<span className={letterClass}>H</span>
				<span className={`${letterClass} _12`}>i...</span>
				<br /> 
				<span className={`${letterClass} _13`}>I</span>
				<span className={`${letterClass} _14`}>'m </span>
				<LetterAnimation letterClass={letterClass} 
				strArray={nameArray}
				idx={15}/>
				</h1>
				<h2>
				<LetterAnimation letterClass={letterClass} 
				strArray={jobArray}
				idx={20}/>
				</h2>
				<h3>Python / JavaScript </h3>
				<Link to='/contact' className='contact-btn'>Contact Me</Link>
				<div className='social-media'>
					<ul>
						<li>
							<a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/prettypandey/' >
								<FontAwesomeIcon icon={faLinkedin} color='#000100' />
							</a>
						</li>
						<li>
							<a target='_blank' rel='noreferrer' href='https://github.com/pandeypearl' >
								<FontAwesomeIcon icon={faGithub} color='#000100' />
							</a>
						</li>
						<li>
							<a target='_blank' rel='noreferrer' href='https://www.behance.com/prettypandey/' >
								<FontAwesomeIcon icon={faBehance} color='#000100' />
							</a>
						</li>
					</ul>
				</div>
			</div>
			
			<Image />
		</div>
		<Loader type='ball-clip-rotate-multiple' />
		</>
	)
}

export default Home;
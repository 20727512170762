import { Link,NavLink } from 'react-router-dom';
import { useState } from 'react';
import LogoP from '../../assets/img/logo-p.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome,faUser,faEnvelope, faSuitcase, faBars, faClose, faGear } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub, faBehance } from '@fortawesome/free-brands-svg-icons';
import './index.scss';

const Navbar = () => {
	const [showNav, setShowNav] = useState(false);
	
	return (
		<div className='nav-bar'>
			<Link className='logo' to='/' onClick={() => setShowNav(false)}>
				<img src={LogoP} alt='logo' />
			</Link>
			<nav className={showNav ? 'mobile-show' : ''}>
				<NavLink
					exact='true' 
					activeClassName='active' 
					to='/'
					onClick={() => setShowNav(false)}>
					<FontAwesomeIcon icon={faHome} color='#4A5859' />
				</NavLink>
				<NavLink
					activeClassName='active' 
					className='about-link' 
					to='/about'
					onClick={() => setShowNav(false)}>
					<FontAwesomeIcon icon={faUser} color='#4A5859' />
				</NavLink>
				<NavLink
					exact='true'
					activeClassName='active'
					className='skills-link'
					to='/skills'
					onClick={() => setShowNav(false)}>
					<FontAwesomeIcon icon={faGear} color='#4A5859' />
				</NavLink>
				<NavLink
					activeClassName='active' 
					className='portfolio-link' 
					to='/portfolio'
					onClick={() => setShowNav(false)}>
					<FontAwesomeIcon icon={faSuitcase} color='#4A5859' />
				</NavLink >
				<NavLink
					activeClassName='active' 
					className='contact-link' 
					to='/contact'
					onClick={() => setShowNav(false)}>
					<FontAwesomeIcon icon={faEnvelope} color='#4A5859' />
				</NavLink >
				<FontAwesomeIcon
					onClick={() => setShowNav(false)}
					icon={faClose}
					color='#f4d6cc'
					size='3x'
					className='close-btn'
				/>
			</nav>
			<ul>
				<li>
					<a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/prettypandey/' >
						<FontAwesomeIcon icon={faLinkedin} color='#4A5859' />
					</a>
				</li>
				<li>
					<a target='_blank' rel='noreferrer' href='https://github.com/pandeypearl' >
						<FontAwesomeIcon icon={faGithub} color='#4A5859' />
					</a>
				</li>
				<li>
					<a target='_blank' rel='noreferrer' href='https://www.behance.com/prettypandey/' >
						<FontAwesomeIcon icon={faBehance} color='#4A5859' />
					</a>
				</li>
			</ul>
			<FontAwesomeIcon
			onClick={() => setShowNav(true)}
			icon={faBars}
			color='#f4d6cc'
			size='3x'
			className='menu-icon'/>
		</div>
	)
}

export default Navbar;
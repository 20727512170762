import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loaders';
import { Helmet } from 'react-helmet';
import './index.scss';

const BadRequestPage = () => {
	return (
	<>
		<Helmet>
			<title>400 - Bad Request</title>
			<meta name='description' content='' />
		</Helmet>
		<div className='container error-page'>
			<div>
				<h1>400</h1>
				<h3>Bad Request</h3>
				<p>Your request is malformed <br /> or contains invalid data.</p>
				<Link to='/' className='back-btn'>
				<FontAwesomeIcon icon={faArrowLeft} />
				&nbsp;Back Home</Link>
			</div>
		</div>
		<Loader type='ball-clip-rotate-multiple' />
	</>
	);
}

export default BadRequestPage;
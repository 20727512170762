import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loaders';
import { Helmet } from 'react-helmet';
import './index.scss';

const ServiceUnavailablePage = () => {
	return (
	<>
		<Helmet>
			<title>503 - Service Unavailable</title>
			<meta name='description' content='' />
		</Helmet>
		<div className='container error-page'>
			<div>
				<h1>503</h1>
				<h3>Service Unavailable</h3>
				<p>The server is temporarily unable <br /> to handle the request.</p>
				<Link to='/' className='back-btn'>
				<FontAwesomeIcon icon={faArrowLeft} />
				&nbsp;Back Home</Link>
			</div>
		</div>
		<Loader type='ball-clip-rotate-multiple' />
	</>
	);
}

export default ServiceUnavailablePage;
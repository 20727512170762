import './App.scss';
import {Routes, Route} from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import PortfolioDetail from './components/PortfolioDetail';
import Dashboard from './components/Dashboard';
import Skills from './components/Skills';
import BadRequestPage from './components/Errors/BadRequestPage';
import UnauthorizedPage from './components/Errors/UnauthorizedPage';
import ForbiddenPage from './components/Errors/ForbiddenPage';
import BadGatewayPage from './components/Errors/BadGatewayPage';
import GatewayTimeoutPage from './components/Errors/GatewayTimeoutPage';
import NotFoundPage from './components/Errors/NotFoundPage';
import RequestTimeoutPage from './components/Errors/RequestTimeoutPage';
import ServerErrorPage from './components/Errors/ServerErrorPage';
import ServiceUnavailablePage from './components/Errors/ServiceUnavailablePage';
// import usePageView from './components/PageView';

function App() {
	// usePageView();
	return (
		<>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/portfolio' element={<Portfolio />} />
					<Route path='/portfolio/:id' element={<PortfolioDetail />} />
					<Route path='/skills' element={<Skills />} />
					<Route path='/dashboard' element={<Dashboard />} />
					{/* Error Pages */}
					<Route path='/400' element={<BadRequestPage />} />
					<Route path='/401' element={<UnauthorizedPage />} />
					<Route path='/403' element={<ForbiddenPage />} />
					<Route path='*' element={<NotFoundPage />} />
					<Route path='/408' element={<RequestTimeoutPage />} />
					<Route path='/502' element={<BadGatewayPage />} />
					<Route path='/504' element={<GatewayTimeoutPage />} />
					<Route path='/500' element={<ServerErrorPage />} />
					<Route path='/503' element={<ServiceUnavailablePage />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;

import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; 
import Home from './home';
import Login from '../Login';
import Loader from 'react-loaders';
import LetterAnimation from '../LetterAnimation';
import { Helmet } from 'react-helmet';
import './index.scss';

const Dashboard = () => {
	const [letterClass, setLetterClass] = useState('text-animate');
	const [user, setUser] = useState(null);
	const auth = getAuth();
	
	useEffect(() => {
		setTimeout(() => {
			setLetterClass('text-animate-hover');
		}, 3000)
		
	}, [])
	
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
		  if (user) {
			setUser(user);
		  } else {
			setUser(null);
		  }
		});
	
		// Cleanup function to unsubscribe when the component unmounts
		return () => unsubscribe();
	}, [auth]); // Add 'auth' to the dependency array
	return (
		<>
			<Helmet>
				<title>Pretty Pandey | Dashboard</title>
				<meta name='description' content='' />
			</Helmet>
			<div className='container dashboard-page'>
				<h1>
					<LetterAnimation
						letterClass={letterClass}
						strArray={'New Project'.split('')}
						idx={15}
					/>
				</h1>
				{user ? <Home /> : <Login />}
			</div>
			<Loader type='ball-clip-rotate-multiple' />
		</>
	)
}

export default Dashboard;